import { captureException } from '@sentry/react';

import { Validator } from '@bootstrap/utils/validator';

/**
 * @description
 * Gets the latest URL path section as a file name or empty string
 * @example
 *  getFileNameFromLink(https://host.com/files/some_section/filename.pdf?accessToken=...) => filename.pdf
 */
export const getFileNameFromLink = (link: string): string => {
  if (!link || !Validator.isUrl(link)) {
    return '';
  }

  try {
    // Create a URL object to parse the link
    const url = new URL(link);
    return url.pathname.split('/').pop() || '';
  } catch (error) {
    // Log unexpected errors and return a default value
    captureException(error);
    return '';
  }
};
