import { useIntl } from 'react-intl';

export type CountryCode =
  | 'NL'
  | 'BE'
  | 'FR'
  | 'GB'
  | 'GE'
  | 'UK'
  | 'UA'
  | 'AT'
  | 'BG'
  | 'DK'
  | 'IT'
  | 'LV'
  | 'LI'
  | 'NO'
  | 'PT'
  | 'PL'
  | 'RO'
  | 'ES'
  | 'SE'
  | 'CH'
  | 'DE';

export const countryCodeMapping: Record<CountryCode, CountryCode> = {
  NL: 'NL',
  BE: 'BE',
  FR: 'FR',
  GB: 'GB',
  GE: 'GE',
  UK: 'UK',
  UA: 'UA',
  AT: 'AT',
  BG: 'BG',
  DK: 'DK',
  IT: 'IT',
  LV: 'LV',
  LI: 'LI',
  NO: 'NO',
  PT: 'PT',
  PL: 'PL',
  RO: 'RO',
  ES: 'ES',
  SE: 'SE',
  CH: 'CH',
  DE: 'DE',
};

/**
 * Country item list
 *
 * Keep GB and UK codes for compatibility.
 * Because we could meet both in address or somewhere else.
 * For example onboarding uses GB
 */
export const useCountries = () => {
  const { formatMessage } = useIntl();

  return [
    { label: formatMessage({ id: 'country.nl' }), value: countryCodeMapping.NL },
    { label: formatMessage({ id: 'country.at' }), value: countryCodeMapping.AT },
    { label: formatMessage({ id: 'country.be' }), value: countryCodeMapping.BE },
    { label: formatMessage({ id: 'country.bg' }), value: countryCodeMapping.BG },
    { label: formatMessage({ id: 'country.ch' }), value: countryCodeMapping.CH },
    { label: formatMessage({ id: 'country.dk' }), value: countryCodeMapping.DK },
    { label: formatMessage({ id: 'country.es' }), value: countryCodeMapping.ES },
    { label: formatMessage({ id: 'country.fr' }), value: countryCodeMapping.FR },
    { label: formatMessage({ id: 'country.ge' }), value: countryCodeMapping.GE },
    { label: formatMessage({ id: 'country.it' }), value: countryCodeMapping.IT },
    { label: formatMessage({ id: 'country.li' }), value: countryCodeMapping.LI },
    { label: formatMessage({ id: 'country.lv' }), value: countryCodeMapping.LV },
    { label: formatMessage({ id: 'country.no' }), value: countryCodeMapping.NO },
    { label: formatMessage({ id: 'country.pl' }), value: countryCodeMapping.PL },
    { label: formatMessage({ id: 'country.pt' }), value: countryCodeMapping.PT },
    { label: formatMessage({ id: 'country.ro' }), value: countryCodeMapping.RO },
    { label: formatMessage({ id: 'country.se' }), value: countryCodeMapping.SE },
    { label: formatMessage({ id: 'country.uk' }), value: countryCodeMapping.GB },
    { label: formatMessage({ id: 'country.ua' }), value: countryCodeMapping.UA },
    { label: formatMessage({ id: 'country.de' }), value: countryCodeMapping.DE },
  ];
};
